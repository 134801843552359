export default {
  id: '313104c342',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="home">\n    <!-- Scrollable page content-->\n    <div class="page-content">\n      <div class="flex center top-circle text-color-white">\n          <div class="text-align-center" style="margin-top: 100px;">\n            <img src="https://cdn-icons-png.flaticon.com/512/15568/15568030.png" alt="" class="icon" height="100">\n          </div>\n          <h2 class="text-color-white">Congratulations!</h2>\n          <p>You can get a loan of upto <span class="f-18 maxLoan"></span></p>\n          <div class="dark-background"></div>\n      </div>\n      <div class="flex center bottom-info">\n        <div class="list no-hairlines">\n          <ul>\n              <li>\n                <a href="/form2/" class="button button-large button-fill text-color-white text-align-center margin-bottom button-gradient button-shine" style="width: 300px; padding: 0;">Finish KYC</a>\n              </li>\n          </ul>\n        </div>\n        <div class="text-color-gray display-flex flex-direction-row block f-12" style="margin-top: 50px; gap: 10px;">\n          <img src="https://cdn-icons-png.flaticon.com/512/10470/10470806.png" alt="" height="24">\n          <span>Please complete your KYC to get the best offers for you, your information is 100% safe & secure with latest encryption technology.</span>\n        </div>\n      </div>\n    </div>\n  </div>\n';
      return r;
    }(this);
  },
  styleScoped: false
};