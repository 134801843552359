export default {
  id: 'd2b9b4cbce',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page" data-name="login">\r\n      <div class="page-content login-page">\r\n        <div class="content-block">\r\n            <div class="login-screen-title">\r\n                <img src="images/logo.png" alt="">\r\n            </div>\r\n            <div class="abstract"></div>\r\n            <div class="login-text">\r\n                <h2 class="text-accented text-align-left">Welcome Back!</h2>\r\n                <p class="text-align-left text-color-gray">Enter your phone number to access your account</p>\r\n            </div>\r\n            <div class="list no-hairlines">\r\n                <ul>\r\n                    <form action="" method="post" name="loginForm" id="loginForm">\r\n                        <li class="item-content item-input dark-background">\r\n                            <div class="item-inner">\r\n                                <div class="item-title item-floating-label">Your Mobile Number</div>\r\n                                <div class="item-input-wrap">\r\n                                    <input type="tel" name="username" id="username" placeholder="Mobile Number" required validate data-error-message="Please enter a valid mobile number" pattern="[0-9]{10}" maxlength="10">\r\n                                </div>\r\n                            </div>\r\n                        </li>\r\n                    </form>\r\n                </ul>\r\n            </div>\r\n            <div class="list no-hairlines">\r\n                <ul>\r\n                    <li>\r\n                        <a href="#" class="button button-large button-fill text-color-white text-align-center login-button button-gradient button-shine m-auto" style="width:92%;">CONTINUE</a>\r\n                    </li>\r\n                </ul>\r\n            </div>\r\n        </div>\r\n      </div>\r\n    </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};