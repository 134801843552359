import $$ from 'dom7';

import HomePage from '../pages/home.f7.html';
import LoginPage from '../pages/login.f7.html';
import SignUpPage from '../pages/signup.f7.html';
import FormPage1 from '../pages/form1.f7.html';
import FormPage2 from '../pages/form2.f7.html';
import FormPage3 from '../pages/form3.f7.html';
import ApprovalPage from '../pages/approval.f7.html';
import WalletPage from '../pages/wallet.f7.html';
import BeneficiaryPage from '../pages/beneficiary.f7.html';
import FeesPage from '../pages/fees.f7.html';
import ProfilePage from '../pages/profile.f7.html';
import LoanPage from '../pages/loanDetails.f7.html';

import NotFoundPage from '../pages/404.f7.html';
// import { isMainThread } from 'worker_threads';
// Create our number formatter.
const formatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  maximumFractionDigits: 0,
});
var routes = [
  {
    path: '/',
    component: LoginPage,
    name: 'login',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        // $$('#username').on('keyup', function() {
        //   var val = $$('#username').val();
        //   if(val.length == 10) {
        //     $$('.login-button').removeClass('disabled');
        //   }
        //   else {
        //     if(!$$('.login-button').hasClass('disabled')) {
        //       $$('.login-button').addClass('disabled');
        //     }
        //   }
        // });
        $$('.login-button').on('click', function() {
          if($$('#loginForm')[0].checkValidity()) {
            login();
          }
        });
        function login() {
          var formData = app.form.convertToData('#loginForm');
          //login
          app.methods.request(
            'https://storrosevyapaar.com/app/php/auth.php',
            {login: true, ...formData},
            'json',
            function() {
              app.preloader.show();
            },
            function(data) {
              app.preloader.hide();
              if(data.status == 'success') {
                localStorage.setItem('phone', data.phone);
                localStorage.setItem('reqToken', data.token);
                localStorage.setItem('mode', data.mode);
                //last four digits of phone number
                var phone = data.phone.toString().slice(-4);
                $$('.OTPnum').html('xxxxxx'+phone);
                app.sheet.open('#sheet-modal-otp');
              }
              else if(data.status == 'error') {
                app.dialog.alert(data.msg, 'Failed to Login');
              }
              else {
                app.dialog.alert(data.msg, 'Failed to Login');
              }
            },
            function() {
              app.preloader.hide();
              app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Login');
            }
          );
        }
        //click on otp-submit
        $$('.otp-button').on('click', function() {
          if($$('#otp')[0].checkValidity()) {
            otp();
          }
        });
        function otp() {
          //login
          app.methods.request(
            'https://storrosevyapaar.com/app/php/auth.php',
            {otpVerify: true, reqToken: localStorage.getItem('reqToken'), otp: $$('#otp').val()},
            'json',
            function() {
              app.preloader.show();
            },
            function(data) {
              app.preloader.hide();
              if(data.status == 'success') {
                setTimeout(() => {
                  localStorage.setItem('phone', data.phone);
                  localStorage.setItem('authToken', data.authToken);
                  localStorage.setItem('mode', data.mode);
                  app.sheet.close('#sheet-modal-otp');
                  if(data.mode == 'login'){
                    app.methods.getUserData(
                      function(user) {
                        if(user.loanData.isApplied) {
                          if(user.loanData.isApproved) {
                            app.dialog.alert(data.msg, function () {
                              app.views.main.router.navigate({name: 'wallet'});
                              app.popup.close();
                            });
                          }
                          else {
                            app.dialog.alert(data.msg, function () {
                              app.views.main.router.navigate({name: 'approval'});
                              app.popup.close();
                            });
                          }
                        }
                        else {
                          if(user.userData.income) {
                            app.dialog.alert(data.msg, function () {
                              app.views.main.router.navigate({name: 'home'});
                              app.popup.close();
                            });
                          }
                          else if(user.userData.name) {
                            app.dialog.alert(data.msg, function () {
                              app.views.main.router.navigate({name: 'form3'});
                              app.popup.close();
                            });
                          }
                          else {
                            app.dialog.alert(data.msg, function () {
                              app.views.main.router.navigate({name: 'form1'});
                              app.popup.close();
                            });
                          }
                        }
                      }
                    );
                  } else {
                    app.dialog.alert(data.msg, function () {
                      app.views.main.router.navigate({name: 'form1'});
                      app.popup.close();
                    });
                  }
                }, 2000);
              }
              else if(data.status == 'error') {
                app.dialog.alert(data.msg, 'Failed to Login');
              }
              else {
                app.dialog.alert(data.msg, 'Failed to Login');
              }
            },
            function() {
              app.preloader.hide();
              app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Login');
            }
          );
        }
      }
    }
  },
  {
    path: '/signup/',
    component: SignUpPage,
    name: 'signup',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var smartSelect = app.smartSelect.create({
          el: '.smart-select',
          openIn: 'sheet',  // Open in a sheet modal,
          closeOnSelect: true,
          on: {
            open: function () {
              //.toolbar-inner .right .link sheet-close change the text to close
              $$('.toolbar-inner .right .link').html('Close');
            }
          }
        });
        //signup button click event
        $$('.signup-button').on('click', function() {
          if($$('#signupForm')[0].checkValidity()) {
            SignUpPage();
          } else {
            
          }
        });
        function SignUpPage() {
          var formData = app.form.convertToData('#signupForm');
          app.methods.request(
            'https://storrosevyapaar.com/app/php/auth.php',
            {signup: true, ...formData},
            'json',
            function() {
              app.preloader.show();
            },
            function(data) {
              app.preloader.hide();
              if(data.status == 'success') {
                app.dialog.alert(data.msg, function() {
                  localStorage.setItem('email', data.email);
                  localStorage.setItem('authToken', data.authToken);
                  localStorage.setItem('mode', data.mode);
                  app.views.main.router.navigate({name: 'form1'});
                });
              }
              else if(data.status == 'error') {
                app.dialog.alert(data.msg, 'Failed to Sign Up');
              }
              else {
                app.dialog.alert(data.msg, 'Failed to Sign Up');
              }
            },
            function() {
              app.preloader.hide();
              app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Sign Up');
            }
          );
        }
      }
    }
  },
  {
    path: '/home/',
    component: HomePage,
    name: 'home',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        //check authentication
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              if(!data.userData.income) {
                app.views.main.router.navigate({name: 'form3'});
              } else {
                localStorage.setItem('page', 'home');
                $$('.maxLoan').html(formatter.format(data.countryData.credit_limit));
              }
            }
          }
        );
      }
    }
  },
  {
    path: '/form1/',
    component: FormPage1,
    name: 'form1',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        $('#gender').select2({minimumResultsForSearch: -1});
        var input = document.querySelectorAll('#dob')[0];
        var dateInputMask = function dateInputMask(elm) {
          elm.addEventListener('keypress', function(e) {
            if(e.keyCode < 47 || e.keyCode > 57) {
              e.preventDefault();
            }
            
            var len = elm.value.length;
            
            // If we're at a particular place, let the user type the slash
            // i.e., 12/12/1212
            if(len !== 1 || len !== 3) {
              if(e.keyCode == 47) {
                e.preventDefault();
              }
            }
            
            // If they don't add the slash, do it for them...
            if(len === 2) {
              elm.value += '/';
            }
        
            // If they don't add the slash, do it for them...
            if(len === 5) {
              elm.value += '/';
            }

            //validate date
            if(len === 10) {
              var date = elm.value.split('/');
              var d = new Date(date[2], date[1] - 1, date[0]);
              if(d && (d.getMonth() + 1) == date[1] && d.getDate() == Number(date[0]) && d.getFullYear() == date[2]) {
                elm.setCustomValidity('');
              } else {
                console.log('invalid date');
                elm.setCustomValidity('Please enter a valid date of birth');
              }
            }
          });
        };
        dateInputMask(input);
        //check authentication
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              if(data.userData.name) {
                app.views.main.router.navigate({name: 'form3'});
              } else {
                //save page
                localStorage.setItem('page', 'form1');
                $$('#country').val(data.userData.country);
                var country_code = data.country_code;
                //get countries json and filter by country code
                $.getJSON('https://storrosevyapaar.com/app/countries.json', function(data) {
                  var country = data.filter(function(item) {
                    return item.iso2 == country_code;
                  });
                  console.log(country);
                  //populate states inside #state select
                  var opt = '';
                  $('#state').empty();
                  $.each(country[0].states, function(key, val) {
                    opt += '<option value="'+val.name+'">'+val.name+'</option>';
                  });
                  $('#state').append(opt);
                  $('#state').select2();
                });
              }
            }
          }
        );
        // var calendarDefault = app.calendar.create({
        //   inputEl: '#dob',
        //   closeOnSelect: true,
        //   openIn: 'customModal',
        //   dateFormat: 'dd.mm.yyyy',
        // });
        //input validation
        $$('.form1-submit').on('click', function() {
          if($$('#form1')[0].checkValidity()) {
            var formData = app.form.convertToData('#form1');
            app.methods.request(
              'https://storrosevyapaar.com/app/php/application.php',
              {form1: true, token: localStorage.getItem('authToken'), ...formData},
              'json',
              function() {
                app.dialog.preloader('Please Wait...');
              },
              function(data) {
                app.dialog.close();
                if(data.status == 'success') {
                  app.dialog.alert(data.msg, function() {
                    app.views.main.router.navigate({name: 'form3'});
                  })
                }
                else {
                  app.dialog.alert(data.msg, 'Failed to Submit');
                }
              },
              function() {
                app.dialog.close();
                app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Submit');
              }
            )
          }
          else {
            
          }
        });
      }
    }
  },
  {
    path: '/form3/',
    component: FormPage3,
    name: 'form3',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        $('#employment').select2({minimumResultsForSearch: -1});
        $('#income').select2({minimumResultsForSearch: -1});
        //check authentication
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              if(data.userData.income) {
                app.views.main.router.navigate({name: 'home'});
              } else {
                //save page
                localStorage.setItem('page', 'form3');
              }
            }
          }
        );
        $$('input.income').keyup(function(event) {
          if($(this).hasClass('income2')) {
            var val = $(this).val().replace(/[$ ,]/g,'');
            $$('input#income2').val(val);
          }
          else {
            // var val = $(this).val().replace('$ ','');
            var val = $(this).val().replace(/[$ ,]/g,'');
            $$('input#income').val(val);
          }
          // skip for arrow keys
          if(event.which >= 37 && event.which <= 40){
           event.preventDefault();
          }
        
          $(this).val(function(index, value) {
              value = value.replace(/,/g,'');
              if(value.indexOf('$') > -1) {
                return app.methods.addComma(value);
              }
              else {
                return '$ '+app.methods.addComma(value);
              }
          });

        });

        $$('.form3-submit').on('click', ()=>{
          if($$('#income')[0].checkValidity()) {
            //update data
            app.methods.request(
              'https://storrosevyapaar.com/app/php/application.php',
              {form3: true, token: localStorage.getItem('authToken'), ...app.form.convertToData('#form3')},
              'json',
              function() {
                app.dialog.preloader('Please Wait...');
              },
              function(data) {
                app.dialog.close();
                if(data.status == 'success') {
                  localStorage.setItem('maxLoan', data.maxLoan);
                  app.dialog.alert(data.msg, function() {
                    setTimeout(()=>{
                      app.preloader.hide();
                      app.views.main.router.navigate({name: 'home'});
                    }, 1000);
                  })
                }
                else {
                  app.dialog.alert(data.msg, 'Failed to Submit');
                }
              },
              function() {
                app.dialog.close();
                app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Submit');
              }
            )
          }
          else {
            
          }
        });
      } 
    }
  },
  {
    path: '/form2/',
    component: FormPage2,
    name: 'form2',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        //check authentication
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else if(data.userData.isKYC) {
              app.views.main.router.navigate({name: 'approval'});
            } else {
              if(!data.userData.income) {
                app.views.main.router.navigate({name: 'form3'});
              } else {
                //save page
                localStorage.setItem('page', 'form2');
                $$('.id-byCountry').html(data.countryData.gov_id);
                $$('#idNumber').attr('data-error-message', 'Enter a valid '+data.countryData.gov_id+' number');
                $$('#idNumber').attr('placeholder', data.countryData.gov_id+' number');
                $$('.tax-byCountry').html(data.countryData.tax_id);
                $$('#taxID').attr('data-error-message', 'Enter a valid '+data.countryData.tax_id+' number');
                $$('#taxID').attr('placeholder', data.countryData.tax_id+' number');
                if(data.kycAPI.aadhaar == '0') {
                  //remove aadhaar validation
                  $$('.aadhaarVerifyBtn').addClass('hidden');
                  $$('.aadhaarVerifyLable').addClass('hidden');
                  $$('#aadhaarVerify').val('false');
                  $$('.form2-submit').removeClass('disabled');
                }
                if(data.kycAPI.pan == '0') {
                  //remove pan validation
                  $$('.panVerifyBtn').addClass('hidden');
                  $$('.panVerifyLable').addClass('hidden');
                  $$('#panVerify').val('false');
                  $$('.form2-submit').removeClass('disabled');
                }
                if(data.kycAPI.bank == '0') {
                  $$('.bankVerifyBtn').addClass('hidden');
                  $$('.bankVerifyLable').addClass('hidden');
                  $$('#bankVerify').val('false');
                  $$('.form2-submit').removeClass('disabled');
                }
              }
            }
          }
        );
        //verify aadhaar card
        $$('.aadhaarVerifyBtn').on('click',function(){
          if (!$$('#idNumber')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyAadhaar();
          }
        });
        //aadhaar verification
        function verifyAadhaar() {
          var aadhaar = $('#idNumber').val();
          var data = 'aadhaar='+aadhaar+'&aadhaarVerify=true';
          app.methods.request(
            'https://storrosevyapaar.com/app/php/kyc.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying Aadhaar Card...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                localStorage.setItem('reference_id', data.reference_id);
                localStorage.setItem('aadhaar', aadhaar);
                app.sheet.open('#sheet-modal-aadhaar');
              }
              else if(data.status == 'error') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //verify otp for aadhaar
        $$('.otp-button-aadhaar').on('click',function(){
          if (!$$('#adh-otp')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyAadhaarOTP();
          }
        });
        function verifyAadhaarOTP() {
          var otp = $('#adh-otp').val();
          var data = 'otp='+otp+'&reference_id='+localStorage.getItem('reference_id')+'&aadhaarVerifyOTP=true+&token='+localStorage.getItem('authToken')+ '&aadhaar='+localStorage.getItem('aadhaar');
          app.methods.request(
            'https://storrosevyapaar.com/app/php/kyc.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying OTP...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                localStorage.removeItem('reference_id');
                localStorage.removeItem('aadhaar');
                app.sheet.close('#sheet-modal-aadhaar');
                $$('.aadhaarVerifyBtn').addClass('hidden');
                $$('.aadhaarVerifyLable').empty();
                $$('.kyc__ver1').removeClass('hidden');
                $$('#aadhaarVerify').val('true');
                $$('#idNumber').attr('readonly', true);
                if($$('#aadhaarVerify').val() == 'true' && $$('#panVerify').val() == 'true' && $$('#bankVerify').val() == 'true') {
                  $$('.form2-submit').removeClass('disabled');
                }
              }
              else if(data.status == 'error') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //taxID on keyup charge it to uppercase
        $$('#taxID').on('keyup', function() {
          var val = $$(this).val().toUpperCase();
          $$(this).val(val);
        });
        //verify pan card
        $$('.panVerifyBtn').on('click',function(){
          if (!$$('#taxID')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyPAN();
          }
        });
        function verifyPAN() {
          var pan = $('#taxID').val();
          var data = 'pan='+pan+'&panVerify=true+&token='+localStorage.getItem('authToken');
          app.methods.request(
            'https://storrosevyapaar.com/app/php/kyc.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying PAN Card...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                $$('.panVerifyBtn').addClass('hidden');
                $$('.panVerifyLable').empty();
                $$('.kyc__ver2').removeClass('hidden');
                $$('#panVerify').val('true');
                $$('#taxID').attr('readonly', true);
                if($$('#aadhaarVerify').val() == 'true' && $$('#panVerify').val() == 'true' && $$('#bankVerify').val() == 'true') {
                  $$('.form2-submit').removeClass('disabled');
                }
              }
              else if(data.status == 'error') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //verify bank account
        $$('.bankVerifyBtn').on('click',function(){
          if (!$$('#bankacc')[0].checkValidity() && !$$('#ifsc')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyBank();
          }
        });
        function verifyBank() {
          var account_no = $('#bankacc').val();
          var ifsc = $('#ifsc').val();
          var data = 'account_no='+account_no+'&bankVerify=true+&ifsc='+ifsc+'&token='+localStorage.getItem('authToken');
          app.methods.request(
            'https://storrosevyapaar.com/app/php/kyc.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying Bank Account...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                $$('.bankVerifyBtn').addClass('hidden');
                $$('.bankVerifyLable').empty();
                $$('.bankVerifyBtn').addClass('hidden');
                $$('.kyc__ver3').removeClass('hidden');
                $$('#bankVerify').val('true');
                $$('#bankacc').attr('readonly', true);
                $$('#ifsc').attr('readonly', true);
                if($$('#aadhaarVerify').val() == 'true' && $$('#panVerify').val() == 'true' && $$('#bankVerify').val() == 'true') {
                  $$('.form2-submit').removeClass('disabled');
                }
              }
              else if(data.status == 'error') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //get ifsc
        $$('#ifsc').on('keyup', function() {
          //capitalize ifsc
          var val = $$('#ifsc').val();
          $$('#ifsc').val(val.toUpperCase());
          $$('.bankBranchName').html('');
          $$('#branch').val('');
          if($$('#ifsc').val().length == 11) {
            $.getJSON('https://ifsc.razorpay.com/'+$$('#ifsc').val(), function(data) {
              $$('.bankBranchName').removeClass('hidden');
              $$('.bankBranchName').html('Branch: '+data.BRANCH);
              $$('#branch').val(data.BRANCH);
              $$('#bankname').val(data.BANK);
              $$('#bankname').attr('readonly', true);
              $$('.item-input-bank').addClass('item-input-focused');
              $$('.item-input-bank').addClass('item-input-with-value');
            });
          }
        })
        //input validation
        $$('.form2-submit').on('click', function() {
          if($$('#form2')[0].checkValidity()) {
            app.methods.request(
              'https://storrosevyapaar.com/app/php/application.php',
              {form2: true, token: localStorage.getItem('authToken'), ...app.form.convertToData('#form2')},
              'json',
              function() {
                app.dialog.preloader('Please Wait...');
              },
              function(data) {
                app.dialog.close();
                if(data.status == 'success') {
                  app.dialog.alert(data.msg, function() {
                    app.views.main.router.navigate({name: 'approval'});
                  })
                }
                else {
                  app.dialog.alert(data.msg, 'Failed to Submit');
                }
              },
              function() {
                app.dialog.close();
                app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Submit');
              }
            );
          }
          else {
            
          }
        });
      }
    }
  },
  {
    path: '/approval/',
    component: ApprovalPage,
    name: 'approval',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            }
            else if(!data.userData.isKYC) {
              app.views.main.router.navigate({name: 'form2'});
            } else {
              localStorage.setItem('page', 'approval');
              var fname = data.userData.name.split(' ')[0];
              $$('.username').html(fname);
              $$('.help-email').html(data.countryData.email);
              $$('.help-phone').html(data.countryData.phone);
              if(!data.loanData.isApplied) {
                $$('.credit__limit').html(formatter.format(data.countryData.credit_limit));
              } else {
                $$('.credit__limit').html(formatter.format(data.loanData.credit_left));
                $$('.apply-btn').attr('href', '#');
                $$('.apply-btn').addClass('disabled');
                $$('.apply-btn').html('Loan Applied');
                $$('.apply-btn').addClass('bg-color-black');
                app.views.main.router.navigate({name: 'wallet'});
              }
            }
          }
        );
        //on open event eligibility popup
        $$('#eligibility-popup').on('popup:open', function() {
          app.preloader.show();
          app.methods.getUserData(
            function(data) {
              app.preloader.hide();
              if(data.loanData.isApplied) {
                $('.confetti').hide();
                $$('.offers-title').html('No Offers Available');
                $$('.offers-title').removeClass('text-accented');
                $$('.offers-title').addClass('text-color-red');
                $$('.offers-img').attr('src', 'https://cdn-icons-png.flaticon.com/512/10308/10308692.png');
                $$('.offers-txt').html('You have claimed '+formatter.format(data.loanData.loanAmnt)+' from your credit limit. You can claim more after repaying the current loan.');
                $$('.offers-btn').attr('href', '/loan-details/');
                $$('.offers-btn').html('View Loan Details');
              } else {
                $$('.maxLoan').html('₹'+app.methods.addComma(data.countryData.credit_limit));
              }
            }
          );
        });
      }
    }
  },
  {
    path: '/wallet/',
    component: WalletPage,
    name: 'wallet',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            }
            else if(!data.loanData.isApplied) {
              if(!data.userData.isKYC) {
                app.views.main.router.navigate({name: 'home'});
              }
              else {
                app.views.main.router.navigate({name: 'approval'});
              }
            }
            localStorage.setItem('page', 'wallet');
            $$('.help-email').html(data.countryData.email);
            $$('.help-phone').html(data.countryData.phone);
            var firstName = data.userData.name.split(' ')[0];
            $$('.username').html(firstName);
            $$('.credit__limit').html(formatter.format(data.loanData.credit_left));
            $$('.applied__loan').html(formatter.format(data.loanData.loanAmnt));
          }
        );
        //on open event eligibility popup
        $$('#eligibility-popup').on('popup:open', function() {
          app.preloader.show();
          app.methods.getUserData(
            function(data) {
              app.preloader.hide();
              if(data.loanData.isApplied) {
                $('.confetti').hide();
                $$('.offers-title').html('No Offers Available');
                $$('.offers-title').removeClass('text-accented');
                $$('.offers-title').addClass('text-color-red');
                $$('.offers-img').attr('src', 'https://cdn-icons-png.flaticon.com/512/10308/10308692.png');
                $$('.offers-txt').html('You have claimed '+formatter.format(data.loanData.loanAmnt)+' from your credit limit. You can claim more after repaying the current loan.');
                $$('.offers-btn').attr('href', '/loan-details/');
                $$('.offers-btn').html('View Loan Details');
              } else {
                $$('.maxLoan').html('₹'+app.methods.addComma(data.countryData.credit_limit));
              }
            }
          );
        });

        // app.methods.request(
        //   'https://storrosevyapaar.com/app/php/getEMI.php',
        //   {token: localStorage.getItem('authToken')},
        //   'html',
        //   function() {
        //     app.preloader.show();
        //   },
        //   function(data) {
        //     app.preloader.hide();
        //     $$('#emi-popup .page-content').html(data);
        //   },
        //   function() {
        //     app.preloader.hide();
        //     app.dialog.alert('Failed to Connect');
        //   }
        // );
        let options = {
          // video.js options
          controls: true,
          bigPlayButton: false,
          loop: false,
          fluid: false,
          height: 600,
          controlBar: {
            // hide fullscreen and volume controls
            fullscreenToggle: true,
            volumePanel: false
          },
          plugins: {
              // videojs-record plugin options
              record: {
                  audio: true,
                  video: true,
                  maxLength: 15,
                  displayMilliseconds: true,
                  debug: true
              }
          }
        };
      }
    }
  },
  {
    path: '/loan-details/',
    component: LoanPage,
    name: 'loan-details',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            }
            else if(data.loanData.isApplied) {
              $$('.home-link').attr('href', '/wallet/');
              if(data.loanData.isApproved) {
                $$('.loanAmount').html(formatter.format(data.loanData.loanAmnt));
                $$('.interestRate').html(data.countryData.roi+'%');
                $$('.tenure').html(data.loanData.loanTenure+' months');
                $$('.emi').html(formatter.format(data.loanData.emi));
                $$('.repaymentDate').html(data.loanData.repayment+ ' of every month');
                $$('.currency').html(data.countryData.currency);
                $$('.loan-img').attr('src', 'images/success.png');
                if(data.loanData.paid && data.loanData.paymentStatus == 1) {
                  $$('.loan-title').html('Congratulations!');
                  $$('.loan-txt').html('Your loan is approved, you can check your loan details below.');
                  $$('.block-txn').empty();
                  $$('.block-txn').append('<div class="list">\
                  <ul>\
                    <li>\
                      <div class="item-content">\
                        <div class="item-inner">\
                          <div class="item-text">EMI paid on '+data.loanData.paymentDate+'</div>\
                          <div class="item-after text-color-green f-16 f-bold"> '+formatter.format(data.loanData.emi)+'</div>\
                        </div>\
                      </div>\
                    </li>\
                    <li>\
                      <div class="item-content">\
                        <div class="item-inner">\
                          <div class="item-text">Loan amount credited to your account on '+data.loanData.dateApproved+'</div>\
                          <div class="item-after text-color-green f-16 f-bold"> '+formatter.format(data.loanData.loanAmnt)+'</div>\
                        </div>\
                      </div>\
                    </li>\
                    </ul>\
                  </div>');
                } else {
                  //$$('.card-emi').addClass('hidden');
                  $$('.loan-title').html('Congratulations!');
                  $$('.loan-txt').html('Your Loan has been approved. Please click on the button below to get the loan amount credited to your account.');
                  $$('.fees-btn').removeClass('hidden');
                }
              }
            } else {
              $$('.block__loanDetails').addClass('hidden');
              $$('.home-link').attr('href', '/approval/');
              $$('.loan-img').attr('src', 'https://cdn-icons-png.flaticon.com/512/10308/10308692.png');
              $$('.loan-title').html('You have not applied for a loan yet');
              $$('.loan-txt').html('Please apply for a loan and get instant approval');
            }
          }
        );
        //get emi details
        $$('.block-emi').empty();
        app.methods.request(
          'https://storrosevyapaar.com/app/php/getEMI.php',
          {token: localStorage.getItem('authToken')},
          'html',
          function() {
            app.preloader.show();
          },
          function(data) {
            app.preloader.hide();
            $$('div.block-emi').html(data);
          },
        );
        $$('.loan-letter').on('click', function() {
          app.preloader.show();
          setTimeout(() => {
            app.preloader.hide();
            app.dialog.alert('Your loan letter has been sent to your email address. Please check your email for the loan documents.', 'Success');
          }, 3000);
        });
        //pay-btn click, open sheet modal
        $$('.pay-btn').on('click', function() {
          sheetPay.open();
        });
        var sheetPay = app.sheet.create({
          content: '<div class="sheet-modal pay-sheet" style="height:auto;">\
          <div class="toolbar no-hairline" style="height: 44px;">\
              <div class="toolbar-inner">\
                <div class="title text-color-white margin-left"><h3 class="text-color-white text-align-left">Confirm Payment for Next EMI</h3></div>\
                <div class="right">\
                  <a href="#" class="link sheet-close">\
                    <i class="icon f7-icons">close</i>\
                  </a>\
                </div>\
              </div>\
            </div>\
              <div class="sheet-modal-inner" style="overflow: auto; height: 400px;">\
                <div class="block text-color-white text-align-center"">\
                  <div class="payment-details">\
                    <h3 class="text-color-white">Payable Amount</h3>\
                    <h3 class="f-20 text-accented payable-amount"></h3>\
                    <div class="upi__data"></div>\
                  </div>\
                  <div class="list no-hairlines inline-labels text-align-left">\
                    <form name="feesFrm" id="feesFrm" action="" method="post">\
                      <ul>\
                      <p class="f-12">Please enter your UTR reference number after making the payment</p>\
                          <li class="item-content item-input">\
                            <div class="item-inner">\
                                <div class="item-input-wrap">\
                                    <input type="hidden" name="paymentAmount" id="paymentAmount" value="0">\
                                    <input type="text" class="TransactionID" name="utr" id="utr" placeholder="UTR Ref Number" required validate data-error-message="Please enter a valid UTR Ref Number">\
                                </div>\
                            </div>\
                          </li>\
                          <li>\
                            <p class="f-10 text-align-left display-flex flex-direction-row" style="gap: 10px;"><label class="checkbox"><input type="checkbox" name="feesCheck" required validate><i class="icon-checkbox"></i></label> I agree to the terms and conditions,\
                            privacy policy and disclaimer of Press Money</p>\
                          </li>\
                      </ul>\
                    </form>\
                  </div>\
                  <div class="list no-hairlines text-align-center">\
                    <ul>\
                        <li>\
                            <a href="#" class="button button-large button-fill text-color-white text-align-center margin-bottom payment-submit button-gradient button-shine disabled" style="width: 100%;">Pay Now</a>\
                        </li>\
                    </ul>\
                  </div>\
                  <div class="text-color-gray display-flex flex-direction-row block f-12 text-align-left" style="margin-top: 10px; gap: 10px;">\
                    <img src="https://cdn-icons-png.flaticon.com/512/6785/6785410.png" alt="" height="24">\
                    <span>Your payment is 100% secure and encrypted with the latest security measures.</span>\
                  </div>\
                </div>\
              </div>\
            </div>',
          // Events
          on: {
            open: function (sheet) {
              app.preloader.show();
              var smartSelect = app.smartSelect.create({
                el: '.smart-select',
                openIn: 'popover',  // Open in a sheet modal,
                closeOnSelect: true,
                on: {
                  open: function () {
                  }
                }
              });
              app.methods.getUserData(
                function(data) {
                  var amount = formatter.format(data.loanData.emi);
                  //roundup amount
                  var payable = Math.round(data.loanData.emi);
                  $$('.payable-amount').html(amount);
                  $$('#paymentAmount').val(payable);
                  app.methods.getBank(
                    function(bank) {
                      app.preloader.hide();
                      $$('.upi__data').empty();
                      $.each(bank.bankData, function(key, val) {
                        var upiLink = 'upi://pay?pa='+val.upi+'&pn='+val.name+'&am='+payable+'&cu=INR';
                        $$('.upi__data').append('<p class="f-12">Send Payment to the following UPI ID</p>\
                          <img src="'+val.qrCode+'" alt="QR Code" class="qr-code" height="200">\
                          <p class="f-12">Scan the QR code or copy the UPI address below</p>\
                          <h3 class="f-14 upi__id">'+val.upi+'</h3>\
                          <div class="display-flex" style="gap: 10px;">\
                              <a href="#" data-upi="'+val.upi+'" class="w-50 button button-fill text-color-white btn-alt margin-top m-auto button-gradient button-shine copy__upi">Copy UPI ID</a>\
                              <a href="'+upiLink+'" class="link external w-50 button button-fill text-color-white btn-alt margin-top m-auto button-gradient button-shine upiIntent">Pay via UPI</a>\
                          </div>');
                      })
                    }
                  );
                }
              );
            },
            opened: function (sheet) {
              $$('input[name="feesCheck"]').on('change', function() {
                if($$(this).is(':checked')) {
                  $$('.payment-submit').removeClass('disabled');
                }
                else {
                  $$('.payment-submit').addClass('disabled');
                }
              });
              //copy upi
              $$('.copy__upi').on('click', function() {
                var upiID = $(this).attr('data-upi');
                var textArea = document.createElement("textarea");
                textArea.value = upiID;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
                app.toast.show({
                  text: 'UPI ID copied!',
                  closeTimeout: 3000,
                });
              });
              //payment-submit on document click
              $$(document).off('click', '.payment-submit');
              $$(document).on('click', '.payment-submit', function() {
                if($$('#feesFrm')[0].checkValidity()) {
                  var formData = app.form.convertToData('#feesFrm');
                  app.methods.request(
                    'https://storrosevyapaar.com/app/php/application.php',
                    {paymentEMI: true, token: localStorage.getItem('authToken'), ...formData},
                    'json',
                    function() {
                      app.dialog.preloader('Please Wait...');
                    },
                    function(data) {
                      app.dialog.close();
                      if(data.status == 'success') {
                        sheetPay.close();
                        app.dialog.alert(data.msg, function() {
                          app.views.main.router.navigate({name: 'wallet'});
                        })
                      }
                      else {
                        app.dialog.alert(data.msg, 'Failed to Submit');
                      }
                    },
                    function() {
                      app.dialog.close();
                      app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Submit');
                    }
                  );
                }
                else {
                  
                }
              });
            },
          }
        });
        $$('.fees-btn').on('click', function() {
          app.views.main.router.navigate('/fees/'+localStorage.getItem('authToken'));
        });
      }
    }
  },
  {
    path: '/apply/',
    component: BeneficiaryPage,
    name: 'apply',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            }
            else if(!data.userData.isKYC) {
              app.views.main.router.navigate({name: 'form2'});
            } else {
              $$('#loanAmount').attr('max', data.countryData.credit_limit);
              $$('#loanAmount').val(5000);
              // INIT SLIDER HERE!
              var range = app.range.create({
                el: '.range-slider',
                max: data.countryData.credit_limit,
                min: 5000,
                step: 1000,
                label: true,
                value: 5000,
              });
            }
          }
        );
        //range slider
        $$('.amount-range').on('range:change', function (e, range) {
          $$('#loanAmount').val(range.value);
          //populate tenure options based on loan amount
          var loanAmount = range.value;
          var tenure = $('#tenure');
          if(loanAmount <= 10000) {
            tenure.empty();
            tenure.append('<option value="3">3 months</option>\
            <option value="6">6 months</option>\
            <option value="9">9 months</option>\
            <option value="12">12 months</option>');
            $('#tenure').niceSelect('update');
          }
          if(loanAmount > 10000 && loanAmount <= 50000) {
            tenure.empty();
            tenure.append('<option value="6">6 months</option>\
            <option value="9">9 months</option>\
            <option value="12">12 months</option>\
            <option value="18">18 months</option>\
            <option value="24">24 months</option>');
            $('#tenure').niceSelect('update');
          }
          if(loanAmount > 50000 && loanAmount <= 100000) {
            tenure.empty();
            tenure.append('<option value="12">12 months</option>\
            <option value="18">18 months</option>\
            <option value="24">24 months</option>\
            <option value="36">36 months</option>\
            <option value="48">48 months</option>');
            $('#tenure').niceSelect('update');
          }
          if(loanAmount > 100000) {
            tenure.empty();
            tenure.append('<option value="12">12 months</option>\
            <option value="18">18 months</option>\
            <option value="24">24 months</option>\
            <option value="36">36 months</option>\
            <option value="48">48 months</option>\
            <option value="60">60 months</option>');
            $('#tenure').niceSelect('update');
          }
        });
        $$('#loanAmount').on('keyup', function() {
          var range = app.range.get('.amount-range');
          var value = $$('#loanAmount').val();
          range.setValue(value);
        });
        $('#tenure').niceSelect();
        $('#repayment').niceSelect();
        $('#purpose').niceSelect();
        //click on loan-submit
        $$('.loan-submit').on('click', function() {
          var formData = app.form.convertToData('#loanForm');
          app.methods.request(
            'https://storrosevyapaar.com/app/php/application.php',
            {loan: true, token: localStorage.getItem('authToken'), ...formData},
            'json',
            function() {
              app.dialog.preloader('Please Wait...');
            },
            function(data) {
              app.dialog.close();
              if(data.status == 'success') {
                app.dialog.alert(data.msg, function() {
                  app.views.main.router.navigate({name: 'wallet'});
                })
              }
              else {
                app.dialog.alert(data.msg, 'Failed to Submit');
              }
            },
            function() {
              app.dialog.close();
              app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Submit');
            }
          );
        });
      }
    }
  },
  {
    path: '/fees/:token',
    component: FeesPage,
    name: 'fees',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        var token = page.route.params.token;
        localStorage.setItem('authToken', token);
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              if(data.loanData.isApplied) {
                localStorage.setItem('page', 'wallet');
                localStorage.setItem('email', data.userData.email);
                localStorage.setItem('mode', 'login');
                if(data.loanData.isApproved) {
                  $$('.loanAmount').html(formatter.format(data.loanData.loanAmnt));
                  $$('.interestRate').html(data.countryData.roi+'%');
                  $$('.tenure').html(data.loanData.loanTenure+' months');
                  $$('.emi').html(formatter.format(data.loanData.emi));
                  $$('.repaymentDate').html(data.loanData.repayment+ ' of every month');
                  $$('.currency').html(data.countryData.currency);
                  if(data.loanData.paid && data.loanData.paymentStatus == 0) {
                    $$('.payment__txt').html('Your payment is in progress. Please wait for the payment to be processed.');
                    $$('.pay-btn').html('In Progress');
                    $$('.pay-btn').addClass('disabled');
                  }
                  if(data.loanData.paid && data.loanData.paymentStatus == 1) {
                    $$('.payment__txt').html('Your payment has been successfully processed. Thank you for your payment. Your loan\
                      will be credited to your account within 24 hours. In case of any issues, please contact us at <span class="text-accented"> '+data.countryData.email+'</span>');
                    $$('.pay-btn').html('Paid');
                    $$('.pay-btn').addClass('disabled');
                  }
                }
              } else {
                localStorage.setItem('page', 'wallet');
                app.views.main.router.navigate({name: 'approval'});
              }
            }
          }
        );
        //pay-btn click, open sheet modal
        $$('.pay-btn').on('click', function() {
          sheetPay.open();
        });
        var sheetPay = app.sheet.create({
          content: '<div class="sheet-modal pay-sheet" style="height:auto;">\
          <div class="toolbar no-hairline" style="height: 44px;">\
              <div class="toolbar-inner">\
                <div class="title text-color-white margin-left"><h3 class="text-color-white text-align-left">Confirm Payment for First EMI</h3></div>\
                <div class="right">\
                  <a href="#" class="link sheet-close">\
                    <i class="icon f7-icons">close</i>\
                  </a>\
                </div>\
              </div>\
            </div>\
              <div class="sheet-modal-inner" style="overflow: auto; height: 600px;">\
                <div class="block text-color-white text-align-center"">\
                  <div class="payment-details">\
                    <h3 class="text-color-white">Payable Amount</h3>\
                    <h3 class="f-20 text-accented payable-amount"></h3>\
                    <div class="upi__data"></div>\
                  </div>\
                  <div class="list no-hairlines inline-labels text-align-left">\
                    <form name="feesFrm" id="feesFrm" action="" method="post">\
                      <ul>\
                          <li class="item-content item-input">\
                            <div class="item-inner">\
                                <div class="item-input-wrap">\
                                    <input type="hidden" name="paymentAmount" id="paymentAmount" value="0">\
                                    <input type="text" class="TransactionID" name="utr" id="utr" placeholder="UTR Ref Number" required validate data-error-message="Please enter a valid UTR Ref Number">\
                                </div>\
                            </div>\
                          </li>\
                          <li>\
                            <p class="f-10 text-align-left display-flex flex-direction-row" style="gap: 10px;"><label class="checkbox"><input type="checkbox" name="feesCheck" required validate><i class="icon-checkbox"></i></label> I agree to the terms and conditions,\
                            privacy policy and disclaimer of Press Money</p>\
                          </li>\
                      </ul>\
                    </form>\
                  </div>\
                  <div class="list no-hairlines text-align-center">\
                    <ul>\
                        <li>\
                            <a href="#" class="button button-large button-fill text-color-white text-align-center margin-bottom payment-submit button-gradient button-shine disabled" style="width: 100%;">Pay Now</a>\
                        </li>\
                    </ul>\
                  </div>\
                  <div class="text-color-gray display-flex flex-direction-row block f-12 text-align-left" style="margin-top: 10px; gap: 10px;">\
                    <img src="https://cdn-icons-png.flaticon.com/512/6785/6785410.png" alt="" height="24">\
                    <span>Your payment is 100% secure and encrypted with the latest security measures.</span>\
                  </div>\
                </div>\
              </div>\
            </div>',
          // Events
          on: {
            open: function (sheet) {
              app.preloader.show();
              var smartSelect = app.smartSelect.create({
                el: '.smart-select',
                openIn: 'popover',  // Open in a sheet modal,
                closeOnSelect: true,
                on: {
                  open: function () {
                  }
                }
              });
              app.methods.getUserData(
                function(data) {
                  var amount = formatter.format(data.loanData.emi);
                  //roundup amount
                  var payable = Math.round(data.loanData.emi);
                  $$('.payable-amount').html(amount);
                  $$('#paymentAmount').val(payable);
                  app.methods.getBank(
                    function(bank) {
                      app.preloader.hide();
                      $$('.upi__data').empty();
                      $.each(bank.bankData, function(key, val) {
                        var upiLink = 'upi://pay?pa='+val.upi+'&pn='+val.name+'&am='+payable+'&cu=INR';
                        $$('.upi__data').append('<p class="f-12">Send Payment to the following UPI ID</p>\
                          <img src="'+val.qrCode+'" alt="QR Code" class="qr-code" height="200">\
                          <p class="f-12">Scan the QR code or copy the UPI address below</p>\
                          <h3 class="f-14 upi__id">'+val.upi+'</h3>\
                          <div class="display-flex" style="gap: 10px;">\
                              <a href="#" data-upi="'+val.upi+'" class="w-50 button button-fill text-color-white btn-alt margin-top m-auto button-gradient button-shine copy__upi">Copy UPI ID</a>\
                              <a href="'+upiLink+'" class="link external w-50 button button-fill text-color-white btn-alt margin-top m-auto button-gradient button-shine upiIntent">Pay via UPI</a>\
                          </div>');
                      })
                    }
                  );
                }
              );
            },
            opened: function (sheet) {
              $$('input[name="feesCheck"]').on('change', function() {
                if($$(this).is(':checked')) {
                  $$('.payment-submit').removeClass('disabled');
                }
                else {
                  $$('.payment-submit').addClass('disabled');
                }
              });
              //copy upi
              $$('.copy__upi').on('click', function() {
                var upiID = $(this).attr('data-upi');
                var textArea = document.createElement("textarea");
                textArea.value = upiID;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
                app.toast.show({
                  text: 'UPI ID copied!',
                  closeTimeout: 3000,
                });
              });
              //payment-submit on document click
              $$(document).off('click', '.payment-submit');
              $$(document).on('click', '.payment-submit', function() {
                if($$('#feesFrm')[0].checkValidity()) {
                  var formData = app.form.convertToData('#feesFrm');
                  app.methods.request(
                    'https://storrosevyapaar.com/app/php/application.php',
                    {payment: true, token: localStorage.getItem('authToken'), ...formData},
                    'json',
                    function() {
                      app.dialog.preloader('Please Wait...');
                    },
                    function(data) {
                      app.dialog.close();
                      if(data.status == 'success') {
                        sheetPay.close();
                        app.dialog.alert(data.msg, function() {
                          app.views.main.router.navigate({name: 'wallet'});
                        })
                      }
                      else {
                        app.dialog.alert(data.msg, 'Failed to Submit');
                      }
                    },
                    function() {
                      app.dialog.close();
                      app.dialog.alert('Something went wrong! Please try again later.', 'Failed to Submit');
                    }
                  );
                }
                else {
                  
                }
              });
            },
          }
        });
      }
    }
  },
  {
    path: '/profile/',
    component: ProfilePage,
    name: 'profile',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.preloader.show();
        app.methods.getUserData(
          function(data) {
            app.preloader.hide();
            if(data.code == '401') {
              app.views.main.router.navigate({name: 'login'});
            } else {
              $$('.username').html(data.userData.name);
              $$('.email').html(data.userData.email);
              $$('.phone').html(data.userData.phone);
              $$('.address').html(data.userData.address);
              $$('.dob').html(data.userData.dob);
              $$('.income').html('Annual Income '+(data.userData.income));
              //userData.gov_id only shows last 4 digits regex
              if(data.userData.dataKYC.isAadhaar == 1) {
                $$('.governmentId').html('Aadhaar *****'+data.userData.dataKYC.aadhaar.slice(-4)+' <i class="f7-icons text-color-greenx f-14">checkmark_alt</i><span class="text-color-greenx f-12"> Verified</span>');
              } else {
                $$('.governmentId').html('Aadhaar *****'+data.userData.dataKYC.aadhaar.slice(-4));
              }
              if(data.userData.dataKYC.isPan == 1) {
                $$('.taxID').html('PAN *****'+data.userData.dataKYC.pan.slice(-4) + ' <i class="f7-icons text-color-greenx f-14">checkmark_alt</i><span class="text-color-greenx f-12"> Verified</span>');
              } else {
                $$('.taxID').html('PAN *****'+data.userData.dataKYC.pan.slice(-4));
              }
              var bank = data.userData.dataKYC.bank;
              // Correct the string format dynamically
              const correctedBank = bank.replace(/([\w]+):/g, '"$1":').replace(/:([\w\s]+)/g, ':"$1"');
              const bankDetails = JSON.parse(correctedBank);
              if(data.userData.dataKYC.isBank == 1) {
                $$('.bankDetails').html('Bank Name: '+bankDetails.bankName+' <i class="f7-icons text-color-greenx f-14">checkmark_alt</i><span class="text-color-greenx f-12"> Verified</span> <br>Account Number: *****'+bankDetails.accountNumber.slice(-4)+'<br>IFSC: '+bankDetails.ifsc+'<br>Branch: '+bankDetails.branch);
              } else {
                $$('.bankDetails').html('Bank Name: '+bankDetails.bankName+'<br>Account Number: *****'+bankDetails.accountNumber.slice(-4)+'<br>IFSC: '+bankDetails.ifsc+'<br>Branch: '+bankDetails.branch);
              }
              if(!data.loanData.isApplied) {
                $$('.home-link').attr('href', '/approval/');
              } else {
                $$('.home-link').attr('href', '/wallet/');
              }
            }
          }
        );
        //click on logout
        $$('.logout').on('click', function() {
          //clear all local storage
          localStorage.clear();
          app.dialog.alert('You have been logged out successfully', function() {
            app.views.main.router.navigate({name: 'login'});
          });
        });
      }
    }
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;